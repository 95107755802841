import React from 'react'
//import ReactPlaceholder from 'react-placeholder';
//import 'react-placeholder/lib/reactPlaceholder.css';

const Loading = ({size, weight, className, color }) => (
	<div className={className ? className : ""}>
				{/*<ReactPlaceholder showLoadingAnimation type={props.type || `text`} rows={7}>
				</ReactPlaceholder>*/}
				<div className={`spinner-border text-primary`} role="status">
					{/* <span className="sr-only">Loading...</span> */}
				</div>
		<style jsx>{`
			.spinner-border {
				width: ${size ? size : "2rem"} !important;
				height: ${size ? size : "2rem"} !important;
				font-size: ${weight ? weight : "18px"} !important;
				color: ${color ? color : "#0d6efd"} !important;
			}
		`}</style>
	</div>
)

export default Loading;

import React from "react"

const Outage = () => {
	return (
        <>
    		<div className="outage-container d-flex h-100 w-100 justify-content-center align-items-center flex-column p-5">
                <img src="/logos/konfhub-logo-purple.svg" width="auto" height="70" alt="KonfHub Outage"/>
				<br/>
				<p>Currently we are expecting an unexpected downtime for the registration and admin dashboard. The cause is an automated database update that has resulted in queries failing. Our tech team is working hard to get it up and running at the earliest. Please be assured that we are addressing it and update you as soon as we are up.</p>
				<br/>
				<p>Thanks for you understanding and support.</p>
    		</div>
            <style jsx>{`
                p {

                }
            `}</style>
        </>
	);
};

export default Outage;

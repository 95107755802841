import React, { Component } from "react";
import Head from "next/head";
import dynamic from "next/dynamic";
import { withRouter } from "next/router";
import Loading from "./../src/components/Loading";
import AuthChecker from "../src/common/AuthChecker";
import StructuredData from "../src/components/StructuredData";
import Outage from "./../src/pages/Outage";
import { seoFunct } from "../src/config/next-seo.config";
import { NextSeo } from "next-seo";

const HomeComponent = dynamic(import("../src/pages/Home/Home"), {
    loading: () => <Loading />,
});

class Home extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let value = this.context;
    }

    render() {
        return (
            <>
                <NextSeo {...seoFunct("homeSeo")} />
                {/* <Head>
                    <title>{`KonfHub - Technical Conferences Platform - Create | Gamify | Amplify`}</title>
                    <meta
                        name="description"
                        content="With KonfHub, creating your tech event and making it successful is as simple as One.. Two.. Three! One: Create and go live within minutes. Two: Gamify for audience engagement. Three: Amplify through contests &amp; multiple channels. "
                    />
                    <meta
                        property="og:description"
                        content="With KonfHub, creating your tech event and making it successful is as simple as One.. Two.. Three! One: Create and go live within minutes. Two: Gamify for audience engagement. Three: Amplify through contests &amp; multiple channels."
                    />
                    <meta
                        property="og:image"
                        content="https://image-dock-uploads-be.s3.ap-south-1.amazonaws.com/image.2021-07-28T19%3A39%3A04.034Z"
                    />
                    <meta property="og:image:width" content="512" />
                    <meta property="og:image:height" content="512" />
                </Head> */}
                <StructuredData data={{
                    "@context": "https://schema.org",
                    "@type": "Organization",
                    "name": "KonfHub",
                    "url": "https://konfhub.com/",
                    "logo": "https://konfhub.com/img/logo.svg",
                    "contactPoint": {
                      "@type": "ContactPoint",
                      "telephone": "",
                      "contactType": "customer service"
                    },
                    "sameAs": [
                      "https://www.facebook.com/konfhub/",
                      "https://www.twitter.com/konfhub",
                      "https://www.youtube.com/c/KonfHubTech",
                      "https://www.linkedin.com/in/konfhub/",
                      "https://instagram.com/konfhub_tech_conference"
                    ]
                }} />
                <HomeComponent newUserLogin={this.props.router.isReady ? this.props.router.query.signIn : false} />
                {/*<Outage/>*/}
            </>
        );
    }
}
export async function getServerSideProps(ctx) {
    await AuthChecker(ctx, false);
    return { props: {} };
}

export default withRouter(Home);